.header-btn-table {
    margin-bottom: 10px;
}

.custom-options {
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-input {
    display: flex;
    align-items: left;
    justify-content: left;
}

.head-text {
    width: 100%;
    position: relative;
    color: black;
}

.head-image img {
    width: 100%;
}

.center__qr {
    position: absolute;
    bottom: 0;
}

.center__text {
    text-align: center;
    width: 75%;
    word-wrap: break-word;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.center__text_btn {
    text-align: center;
    width: 75%;
    word-wrap: break-word;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.button-canvas {
    width: 100%;
    height: 20%;
    position: relative;
}

.button-canvas img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.button-canvas div h2 {
    color: white;
}

.swal2-container {
    z-index: 99993 !important;
}

.react-bootstrap-table table {
    table-layout: auto !important;
}

.tab-text-amb {
    font-size: 2rem !important;
}

.carousel-item>img {
    width: 100%;
}