// Gaps
@use "sass:math";

$row_gaps: (
    "xs": 10px,
    "sm": 20px,
    "md": 40px,
    "lg": 60px) !default;

// vertical gap
.row.vertical-gap {
    margin-top: -5px;

    >.col,
    >[class*="col-"] {
        padding-top: 10px;
    }
}

@each $screen,
$size in $row_gaps {
    .row.#{$screen}-gap {
        margin-right: #{math.div($size, -2)};
        margin-left: #{math.div($size, -2)};

        >.col,
        >[class*="col-"] {
            padding-right: #{$size * 0.5};
            padding-left: #{$size * 0.5};
        }

        // vertical
        &.vertical-gap {
            margin-top: #{math.div($size, -1)};

            >.col,
            >[class*="col-"] {
                padding-top: $size;
            }
        }
    }
}