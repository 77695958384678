.welcome-footer-m {
    position: fixed;
    bottom: 0;
    width: 100% !important;
    background-color: white;
    padding-top: 20px;
}

.welcome-footer-img-m {
    bottom: 0;
    width: 100%;
}