.welcome-preview-layout {
    width: auto;
}

.welcome-img-drag {
    position: relative;
    width: 100%;
    height: 100%;
}

.welcome-body-corner-left {
    text-align: center;
    border-right: 1px solid black;
    width: 25%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.welcome-body-corner-right {
    text-align: center;
    border-top: 0px !important;
    border-right: 0px !important;
    border-left: 1px solid black;
    border-bottom: 0px !important;
    border: 1px solid black;
    float: right;
    width: 25%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.welcome-body-bottom {
    text-align: center;
    border-top: 0px !important;
    border-right: 1px solid black;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    border: 1px solid black;
    width: 100%;
    height: 5%;
    position: relative;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-clock {
    padding: 10 10 10 10;
    width: 100% !important;
    height: 100% !important;
}

.react-clock__face {
    border: 0px !important;
    margin: 3px;
}


.btn-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.layer1,
.layer2 {
    grid-column: 1;
    grid-row: 1;
}

.layer3 {
    grid-column: 1;
    grid-row: 1;
    margin-left: auto;
    margin-right: 0;
}

.layer4,
.layer6 {
    grid-column: 1;
    grid-row: 1;
}

.layer5,
.layer7 {
    grid-column: 1;
    grid-row: 1;
    margin-left: auto;
    margin-right: 0;
}

.btn-ticker {
    position: relative;
    float: right;
    top: 1px;
    right: 1px;
}

.btn-media {
    border: 4px solid black;
}

.check-badge {
    position: absolute;
    right: 5px;
    top: -10px;
    background: #026FAB;
    text-align: center;
    border-radius: 10px 10px 10px 10px;
    color: white;
    padding: 5px 10px;
    z-index: 10;
}

.clear-badge {
    position: absolute;
    right: 2px;
    top: 2px;
    background: white;
    border: 2px solid black;
    text-align: center;
    border-radius: 10px 10px 10px 10px;
    color: white;
    padding: 5px 10px;
    z-index: 11;
}