.welcome-monitor {
    text-align: center;
    position: relative;
    top: 0;
    border: 1px;
}

.welcome-monitor-header {
    font-size: 2em;
    border-bottom: 1px solid;
}

.welcome-monitor-body {
    font-size: 3em;
}

.fixed-header thead th {
    /* the thead selector is required because there are th elements in the body */
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
}

.swal-monitor-title {
    font-size: 4rem !important;
}

.swal-monitor-container {
    width: auto;
}

.row {
    margin: 0;
}

.digital-clock {
    padding: 10px;
    border-radius: 10px;
    background-color: lightblue;
}